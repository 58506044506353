import { GLPieChart } from "@group-link-one/grouplink-components";

import { useTransmissionStatus } from "./useTransmissionStatus";

export const TransmissionStatusChart = () => {
  const { data } = useTransmissionStatus();

  return (
    <GLPieChart
      data={data}
      hasDefaultValue
      defaultValueAsTotal
      defaultDescription="Total"
      pieChartOptions={{
        innerRadius: 80,
        outerRadius: 110,
      }}
    />
  );
};
