import {
  GL_BAR_CHART,
  GL_GLStation,
  GL_HEART,
  GL_SETTINGS,
  GL_SHARE,
  GLAsideItemProps,
  useGLMenuMobile,
  useI18n,
} from "@group-link-one/grouplink-components";
import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { useFlags } from "../../Context/FlagsProvider";
// import { useFlags } from "../../Context/FlagsProvider";
import { verifyIfPathIsActive } from "../../utils/verifyIfPathIsActive";

export enum MainRoutes {
  SETTINGS = "/settings",
  REPORTS = "/reports",
  DEVICE_LIST = "/devices",
  GROUP_LIST = "/devices/groups",

  HEALTH_CHECK = "/health-check",
  HEALTH_CHECK_MAP = "/health-check/map",

  EVENT_LIST = "/network/event-list",
}

export function useMainLayout() {
  const { t } = useI18n();
  const navigate = useNavigate();
  const { handleOpenMenuMobile, isMenuMobileOpen } = useGLMenuMobile();
  const { hasBetaPrivileges, flagsAlreadyLoaded, user, hasPrivileges } = useFlags();

  const navListTop: GLAsideItemProps[] = useMemo(() => {
    const allPages: GLAsideItemProps[] = [
      {
        id: 1,
        Icon: GL_BAR_CHART,
        text: t("pages.reports.title"),
        isToAppear: hasPrivileges(["devices_activated_last_reading"]),
        active: verifyIfPathIsActive(MainRoutes.REPORTS),
        dropdownItems: [],
        onClick: () => {
          navigate(MainRoutes.REPORTS);
        },
      },
      {
        id: 2,
        Icon: GL_GLStation,
        text: t("deviceList.title"),
        active: verifyIfPathIsActive(MainRoutes.DEVICE_LIST),
        isToAppear: true,
        dropdownItems: [
          {
            id: 1,
            // Icon: GL_GLStation,
            text: t("deviceList.subtitle"),
            isToAppear: true,
            active: verifyIfPathIsActive(MainRoutes.DEVICE_LIST),
            onClick: () => {
              navigate(MainRoutes.DEVICE_LIST);
            },
          },
          {
            id: 2,
            text: t("groupList.subtitle"),
            // isToAppear: hasBetaPrivileges("beta_client") || hasBetaPrivileges("beta"),
            isToAppear: hasPrivileges(["organization_device_group"]),
            active: verifyIfPathIsActive(MainRoutes.GROUP_LIST),
            onClick: () => {
              navigate(MainRoutes.GROUP_LIST);
            },
          }
        ],
        onClick: () => {
        },
      },
      {
        id: 3,
        Icon: GL_HEART,
        text: "Saude dos Dispositivos",
        active: false,
        isToAppear: hasBetaPrivileges("beta"),
        dropdownItems: [
          {
            id: 1,
            text: "Overview",
            isToAppear: hasBetaPrivileges("beta"),
            active: verifyIfPathIsActive(MainRoutes.HEALTH_CHECK),
            onClick: () => {
              navigate(MainRoutes.HEALTH_CHECK);
            },
          },
          {
            id: 2,
            text: "Mapa de Dispositivos",
            isToAppear: hasBetaPrivileges("beta"),
            active: verifyIfPathIsActive(MainRoutes.HEALTH_CHECK_MAP),
            onClick: () => {
              navigate(MainRoutes.HEALTH_CHECK_MAP);
            },
          },
        ],
        onClick: () => {
        },
      },
      {
        id: 4,
        Icon: GL_SHARE,
        text: t("eventList.subtitle"),
        isToAppear: hasPrivileges(["devices_activated_last_reading"]),
        active: verifyIfPathIsActive(MainRoutes.EVENT_LIST, false),
        dropdownItems: [],
        onClick: () => navigate(MainRoutes.EVENT_LIST),
      }
    ]

    return allPages
      .filter((item) => {
        if (item.isToAppear) return item;
      })
      .map((item) => {
        if (item.dropdownItems?.length) {
          const newItem = {
            ...item,
            dropdownItems: item.dropdownItems.filter((dropdownItem) => {
              if (dropdownItem.isToAppear) return dropdownItem;
            }),
          };

          return newItem;
        }

        return item;
      });
  }, [hasPrivileges, hasBetaPrivileges, user, flagsAlreadyLoaded])

  const navListBottom: GLAsideItemProps[] = [
    {
      id: 1,
      Icon: GL_SETTINGS,
      text: t("pages.settings.title"),
      active: verifyIfPathIsActive(MainRoutes.SETTINGS, false),
      onClick: () => {
        navigate(MainRoutes.SETTINGS);
      },
    },
  ];

  const removeStyle = () => {
    const container = document.querySelector(".page-animated");
    if (container) {
      container.removeAttribute("style");
    }
  };

  useEffect(() => {
    if (isMenuMobileOpen) {
      handleOpenMenuMobile();
    }

    removeStyle();
  }, [navigate]);

  return { navListTop, navListBottom };
}
