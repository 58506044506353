import { useAuth } from "@group-link-one/gl-auth";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";

import { env } from "../env";

export function useCallbackToModalLogout() {
  const [, , removeValue] = useLocalStorage(`@utilities-${env.NODE_ENV}`, "");
  const navigate = useNavigate();
  const { manipulateReduxValues } = useAuth();

  function callbackFnToModalLogout() {
    removeValue();
    navigate("/login");
    manipulateReduxValues("SET_ACCESS_TOKEN", "");
    manipulateReduxValues("SET_HASHED_TOKEN", "");
    manipulateReduxValues("SET_USER", "");
    manipulateReduxValues("SET_ACTION_ON_IDLE", "");
    manipulateReduxValues("SET_ACCESS_TOKEN_IS_VALID", "");
  }

  return {
    callbackFnToModalLogout,
  };
}
