import { useRegisterDeviceStore } from "@group-link-one/grouplink-components";
import { useMediaQuery } from "usehooks-ts";

import { useFlags } from "../../../Context/FlagsProvider";
import { GetDevicesActivatedLastReadingsResponse } from "../../../Services/deviceListService/useDeviceListService.types";
import { useDetailsDeviceStore } from "../store/details-device-store";

export const useDeviceListCard = () => {
  const { hasPrivileges } = useFlags();
  const { actions: detailsDeviceActions } = useDetailsDeviceStore();
  const { actions: registerDeviceActions, state: registerDeviceState } =
    useRegisterDeviceStore();
  const isMobile = useMediaQuery("(max-width: 1024px)");

  function onAddressClick(lat: number, lng: number) {
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
    window.open(googleMapsUrl, "_blank");
  }

  function onDetailsClick(item: GetDevicesActivatedLastReadingsResponse) {
    detailsDeviceActions.setDevice(item);
    detailsDeviceActions.setModalIsOpen(true);
  }

  function onEditClick(item: GetDevicesActivatedLastReadingsResponse) {
    registerDeviceActions.setDeviceId(item.device_id);
    registerDeviceActions.setDeviceName(item.channels[0].name || "");
    registerDeviceActions.setRemoteId(item.channels[0].remote_id || "");
    registerDeviceActions.setAddressSelectedInfo({
      address: item.meta.display_address || "",
      lat: item.meta.latitude,
      lng: item.meta.longitude,
      cep: registerDeviceState.addressSelectedInfo.cep,
      city: "",
      state: "",
    });
  }

  function verifyIfUserHasPermissionToEdit() {
    return hasPrivileges(["device_activation_patch"]);
  }

  return {
    isMobile,
    onAddressClick,
    onDetailsClick,
    onEditClick,
    verifyIfUserHasPermissionToEdit,
  };
};
