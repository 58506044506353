import { createWithEqualityFn } from "zustand/traditional";

import { GetDevicesActivatedLastReadingsResponse } from "../../../Services/deviceListService/useDeviceListService.types";

export interface HealthCheckStoreState {
  deviceModalIsOpen: boolean;
  deviceSelected: undefined | GetDevicesActivatedLastReadingsResponse

}

export interface HealthCheckStoreActions {
  setDeviceModalIsOpen: (deviceModalIsOpen: boolean) => void;
  setDeviceSelected: (deviceSelected?: GetDevicesActivatedLastReadingsResponse) => void;
  resetAll: () => void;
}

export interface HealthCheckStore {
  state: HealthCheckStoreState;
  actions: HealthCheckStoreActions;
}

export const useHealthCheckStore = createWithEqualityFn<HealthCheckStore>((set) => ({
  state: {
    deviceModalIsOpen: false,
    deviceSelected: undefined,
  },
  actions: {
    setDeviceModalIsOpen: (deviceModalIsOpen) =>
      set((state) => ({
        state: {
          ...state.state,
          deviceModalIsOpen,
        }
      })),

    setDeviceSelected: (deviceSelected) =>
      set((state) => ({
        state: {
          ...state.state,
          deviceSelected,
        }
      })),

    resetAll: () =>
      set((state) => ({
        state: {
          ...state.state,
          deviceModalIsOpen: false,
        }
      })),
  },
}));
