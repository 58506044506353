import { GLMainContent } from "@group-link-one/grouplink-components";
import { Helmet } from "react-helmet";

import { HealthCheckContent } from "./Content/HealthCheckContent";
import { useHealthCheck } from "./useHealthCheck";

export function HealthCheck() {
  const { t } = useHealthCheck();

  return (
    <>
      <GLMainContent
        title="Saúde dos dispositivos"
        subtitle={t("deviceList.title")}
        hasList={true}
        content={<HealthCheckContent />}
        // rightContent={<DeviceListRightContent />}
        isOnTopHeaderContent={true}
      />

      <Helmet title="Health Check" />
    </>

  );
}
