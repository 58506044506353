import { useGLPagination, useInfiniteScroll, useToast } from "@group-link-one/grouplink-components";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useEffect, useMemo, useRef, useState } from "react";

import { useExtractURLSearch } from "../../../hooks/useExtractURLSearch";
import { useDeviceListService } from "../../../Services/deviceListService/useDeviceListService";
import {
  DeviceMeasurementCategory,
  GetDevicesActivatedLastReadingsParams,
  GetDevicesActivatedLastReadingsResponse,
  GetDevicesPendingActivationResponse
} from "../../../Services/deviceListService/useDeviceListService.types";
import { useDeviceListStore } from "../store/device-list-store";

export const DEVICE_LIST_IPP = 20;
type CacheKeyMap = DeviceMeasurementCategory | "none";

export const useDeviceListAllDevices = () => {
  const [distanceFromHeader, setDistanceFromHeader] = useState(0);

  const { getDevicesActivatedLastReadings, getDevicesPendingActivation } =
    useDeviceListService();

  const { state: paginationState, actions: paginationActions } =
    useGLPagination();

  const queryClient = useQueryClient();

  const queryDeviceID = useExtractURLSearch("device_id");

  const { state: deviceListState } = useDeviceListStore();

  const { addToast } = useToast();
  const { onInfiniteScroll } = useInfiniteScroll();

  const listContainerRef = useRef<HTMLDivElement>(null);

  const activeTab = useMemo(() => {
    return paginationActions.getActiveTabById(paginationState.activeTabId);
  }, [paginationState.activeTabId]);

  const searchDeviceID = useMemo(() => {

    // if (
    //   paginationState.search.length === 0 &&
    //   queryDeviceID &&
    //   queryDeviceID.length === 10
    // ) {
    //   return isNaN(Number(queryDeviceID))
    //     ? undefined
    //     : [Number(queryDeviceID)];
    // }

    if (paginationState.search.length >= 0 &&
      paginationState.search.length < 10
    ) return undefined;

    return isNaN(Number(paginationState.search))
      ? undefined
      : [Number(paginationState.search)];

  }, [paginationState.search, queryDeviceID]);

  const useCasesSelected: DeviceMeasurementCategory | undefined = useMemo(() => {
    return deviceListState?.useCaseOptionsSelecteds?.map((useCase) =>
      useCase.id as DeviceMeasurementCategory)[0] || undefined;

  }, [deviceListState?.useCaseOptionsSelecteds]);

  const cacheKeyByUseCase = useMemo(() => {
    const useCaseSelected =  deviceListState?.useCaseOptionsSelecteds?.map((useCase) =>
      useCase.id as DeviceMeasurementCategory)[0] || undefined;

    const cacheKeyMap: Record<CacheKeyMap, string> = {
      energy: "energy-devices",
      gas: "gas-devices",
      pressure: "pressure-devices",
      water: "water-devices",
      none: "all-devices",
    }

    return useCaseSelected && useCaseSelected in cacheKeyMap ? cacheKeyMap[useCaseSelected] : "all-devices";

  }, [deviceListState?.useCaseOptionsSelecteds]);

  const {
    data: deviceList,
    isLoading: deviceListIsLoading,
    isError: deviceListIsErrored,
  } = useQuery({
    queryKey: [
      "devices-activated-last-readings",
      searchDeviceID,
      useCasesSelected,
    ],
    enabled: activeTab?.id === 1,
    queryFn: async () => {
      const { optionsToStoreNextPageToken } =
        paginationActions.getNextPageToken();

      try {
        const response = await getDevicesActivatedLastReadings({
          ipp: DEVICE_LIST_IPP,
          next_page_token: undefined,
          device_id: searchDeviceID,
          object_readings: true,
          device_measurement_category: useCasesSelected,
        });

        paginationActions.setNextPageToken({
          hasMore: response.has_more,
          nextPageToken: response.next_page_token || undefined,
          optionsToStoreNextPageToken,
          cacheKeyParam: cacheKeyByUseCase
        });

        return response.rows;
      } catch (err: unknown) {
        if (err instanceof AxiosError) {
          addToast({
            type: "error",
            title: "Device ID not found",
            message: "Please enter a valid Device ID",
          })
        }
      }

    },
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  });

  const {
    data: pendingDeviceList,
    isLoading: pendingDeviceListIsLoading,
    isError: pendingDeviceListIsErrored,
  } = useQuery({
    queryKey: [
      "devices-pendings-last-readings",
      searchDeviceID,
      // useCasesSelected,
    ],
    enabled: activeTab?.id === 2,
    queryFn: async () => {
      const { optionsToStoreNextPageToken } =
        paginationActions.getNextPageToken();

      try {
        const response = await getDevicesPendingActivation({
          ipp: DEVICE_LIST_IPP,
          next_page_token: undefined,
          device_id: searchDeviceID,
        });

        paginationActions.setNextPageToken({
          hasMore: response.has_more,
          nextPageToken: response.next_page_token || undefined,
          optionsToStoreNextPageToken,
        });

        return response.rows;
      } catch (err: unknown) {
        if (err instanceof AxiosError) {
          addToast({
            type: "error",
            title: "Device ID not found",
            message: "Please enter a valid Device ID",
          })
        }
      }

    },
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  });

  async function getMoreDevicesOnScroll() {
    const activeTabIdMap = {
      "tab-1": {
        do: async (options: GetDevicesActivatedLastReadingsParams) => {
          return await getDevicesActivatedLastReadings(options);
        },
        cachekey: "devices-activated-last-readings",
        queryKey: ["devices-activated-last-readings", searchDeviceID, useCasesSelected],
      },
      "tab-2": {
        do: async (options: GetDevicesActivatedLastReadingsParams) => {
          return await getDevicesPendingActivation(options);
        },
        cachekey: "devices-pendings-last-readings",
        queryKey: ["devices-pendings-last-readings", searchDeviceID],
      },
    };

    if (paginationState.isFetchingInfiniteScroll) return;

    const { nextPageToken, optionsToStoreNextPageToken } =
      paginationActions.getNextPageToken(activeTab?.id === 1 ? cacheKeyByUseCase : undefined);

    if (!nextPageToken) return;

    const options: GetDevicesActivatedLastReadingsParams = {
      ipp: DEVICE_LIST_IPP,
      next_page_token: nextPageToken,
      device_id: searchDeviceID,
      object_readings: true,
      device_measurement_category: useCasesSelected,
    };

    paginationActions.setIsFetchingInfiniteScroll(true);

    const tabMapActive =
      activeTabIdMap[`tab-${activeTab?.id}` as keyof typeof activeTabIdMap];

    const response = await tabMapActive.do(options);

    paginationActions.setIsFetchingInfiniteScroll(false);

    paginationActions.setNextPageToken({
      hasMore: response.has_more,
      nextPageToken: response.next_page_token || undefined,
      optionsToStoreNextPageToken,
      cacheKeyParam: activeTab?.id === 1 ? cacheKeyByUseCase : undefined
    });

    const currentDeviceList:
      | GetDevicesActivatedLastReadingsResponse[]
      | GetDevicesPendingActivationResponse[]
      | undefined = queryClient.getQueryData(tabMapActive.queryKey);

    if (!currentDeviceList) return;

    queryClient.setQueryData(
      tabMapActive.queryKey,
      [...currentDeviceList, ...response.rows]
    );
  }

  function invalidateAllQueries() {
    queryClient.invalidateQueries({
      queryKey: ["devices-activated-last-readings"],
    });
  }

  const currentDeviceList = useMemo(() => {

    const activeTabIdMap = {
      "tab-1": deviceList,
      "tab-2": pendingDeviceList
    }

    return activeTabIdMap[`tab-${activeTab?.id}` as keyof typeof activeTabIdMap] || [];
  }, [activeTab?.id, deviceList, pendingDeviceList]);

  const currentDeviceListIsLoading = useMemo(() => {

    const activeTabIdMap = {
      "tab-1": deviceListIsLoading,
      "tab-2": pendingDeviceListIsLoading
    }

    return activeTabIdMap[`tab-${activeTab?.id}` as keyof typeof activeTabIdMap];
  }, [activeTab?.id, deviceListIsLoading, pendingDeviceListIsLoading]);

  const currentDeviceListIsErrored = useMemo(() => {

    const activeTabIdMap = {
      "tab-1": deviceListIsErrored,
      "tab-2": pendingDeviceListIsErrored
    }

    return activeTabIdMap[`tab-${activeTab?.id}` as keyof typeof activeTabIdMap]
  }, [activeTab?.id, deviceListIsErrored, pendingDeviceListIsErrored]);

  const isToShowDeviceList = !currentDeviceListIsLoading &&
    !currentDeviceListIsLoading &&
    currentDeviceList &&
    currentDeviceList.length > 0;

  const isToShowEmptyState = currentDeviceList && currentDeviceList.length === 0;
  const isToShowError = !currentDeviceListIsLoading && currentDeviceListIsErrored;

  useEffect(() => {
    paginationActions.tabsActions.setOnClick(paginationState.tabs[0], () => {
      paginationActions.setActiveTab(1);
    });

    if (paginationState.tabs[1]) {
      paginationActions.tabsActions.setOnClick(paginationState.tabs[1], () => {
        paginationActions.setActiveTab(2);
      });
    }

    invalidateAllQueries();

  }, []);

  useEffect(() => {
    paginationActions.setIsLoading(deviceListIsLoading);
  }, [deviceListIsLoading]);

  useEffect(() => {
    setTimeout(() => {
      const header = document.querySelector("header")?.getBoundingClientRect();

      if (listContainerRef.current) {
        setDistanceFromHeader(listContainerRef.current.offsetTop - (header?.height || 0));
      }

    }, 200)
  }, [])

  return {
    activeTab,
    currentDeviceList,
    deviceList,
    deviceListIsErrored,
    distanceFromHeader,
    deviceListIsLoading,
    currentDeviceListIsLoading,
    paginationState,
    paginationActions,
    listContainerRef,
    tabs: paginationState.tabs,
    devicesCount: 0,
    isToShowDeviceList,
    isToShowEmptyState,
    isToShowError,
    getMoreDevicesOnScroll,
    onInfiniteScroll
  };
};
