import { GLModalRegisterDevice } from "@group-link-one/grouplink-components";

import { useModalEditDevice } from "./useModalEditDevice";

export function ModalEditDevice() {
  const { onPlaceSelect, onEditDeviceFn } = useModalEditDevice();

  return (
    <GLModalRegisterDevice
      modalType="edit"
      onRegisterDevice={(deviceData) => onEditDeviceFn(deviceData)}
      onPlaceSelect={(placeId) => onPlaceSelect(placeId)}
    />
  );
}
