import { useNavigate } from "react-router-dom";

import { useHealthCheckStore } from "../store/health-check-store";

export const useDeviceModal = () => {

  const {
    state: healthCheckState,
    actions: healthCheckActions,
  } = useHealthCheckStore()

  const navigate = useNavigate();

  function onClickSeeDetails() {
    navigate(`/devices?device_id=${healthCheckState.deviceSelected?.device_id}`)
  }

  return {
    healthCheckState,
    healthCheckActions,
    onClickSeeDetails
  };
};
