import { ThemeChanger } from "@group-link-one/grouplink-components";
import { useEffect } from "react";

import { IsMaintenance } from "../Context/FlagsProvider";
import { AuthRoutes } from "./auth.routes";
import { AuthenticatedRoutes } from "./authenticated.routes";

export const Routes = () => {
  useEffect(() => {
    ThemeChanger({
      light: "#F6F8F9",
      dark: "#313337",
    });
  }, []);

  return (
    <>
      <IsMaintenance>
        <AuthRoutes />
        <AuthenticatedRoutes />
      </IsMaintenance>
    </>
  );
};
