import { useLocation } from "react-router-dom";

export const useExtractURLSearch = (param: string) => {
  const { search } = useLocation();

  const extractTokenFromSearch = () => {
    const params = new URLSearchParams(search);
    return params.get(param);
  };

  return extractTokenFromSearch();
}
