import { GL_COLORS, GLBox, GLRelativeFilterTime, GLTypography } from "@group-link-one/grouplink-components";
import { GoogleMap, MarkerClusterer, useJsApiLoader } from "@react-google-maps/api";

import { env } from "../../../env";
import { DeviceModal } from "../DeviceModal/DeviceModal";
import { HealthCheckMapFooter } from "./HealthCheckMapStyle";
import { MarkerMap } from "./Marker/Marker";
import { useHealthCheckMap } from "./useHealthCheckMap";

const containerStyle = {
  width: "100%",
  height: "100%"
};

export function HealthCheckMap() {
  const {
    center,
    devicesFetched,
    relativeFilterTimeOptions,
    markerIcon,
    mapStyle,
    onLoad,
    onUnmount
  } = useHealthCheckMap();

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: env.REACT_APP_GOOGLE_MAPS_API_KEY
  });

  return isLoaded ? (
    <>
      <GoogleMap
        mapContainerStyle={{
          ...containerStyle,
          borderRadius: "10px",
        }}
        options={{
          styles: mapStyle,
          disableDefaultUI: true,
        }}
        center={center}
        zoom={10}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >

        <GLBox
          width="fit-content"
          direction="column"
          gap={0}
          style={{
            position: "absolute",
            top: 40,
            left: 40,
          }}
        >
          <GLTypography
            text="Dispositivos"
            color="ACCENT_COLOR"
            weight={600}
            as="h2"
            fontSize={4.2}
            style={{
              textTransform: "uppercase",
            }}
          />
          <GLTypography
            text="Mapa dos dispositivos"
            color="FONT_COLOR"
            weight={600}
            as="h2"
            fontSize={7}

          />
        </GLBox>

        <GLBox
          width="fit-content"
          style={{
            position: "absolute",
            top: 40,
            right: 40,
          }}
        >
          <GLRelativeFilterTime
            {...relativeFilterTimeOptions}
          />
        </GLBox>

        {devicesFetched && devicesFetched.length > 0 && (
          <MarkerClusterer
            options={{
              maxZoom: 14,

            }}
          >
            {(clusterer) =>
              <>
                {devicesFetched.map(device => (
                  <MarkerMap
                    device={device}
                    clusterer={clusterer}
                    markerIcon={markerIcon}
                    key={device.device_id}
                  />
                ))}
              </>
            }
          </MarkerClusterer>
        )}

        <HealthCheckMapFooter width="fit-content " justify="space-between" align="flex-end">
          <GLBox width="fit-content" direction="column">
            <GLBox
              width="fit-content"
              gap={0}
              style={{
                backgroundColor: GL_COLORS.PURPLE,
                borderRadius: "16px",
                position: "relative",
              }}
            >
              <GLBox
                direction="column"
                borderRadius="16px 0 0 16px"
                gap={5}
                style={{
                  borderRight: `1px solid ${GL_COLORS.SHADOW_PURPLE}`,
                  padding: "20px 25px",
                }}
              >
                <GLBox align="center">
                  <GLBox
                    width={10}
                    height={10}
                    style={{
                      backgroundColor: GL_COLORS.SUCCESS,
                      borderRadius: "50%",
                      outline: `2px solid ${GL_COLORS.WHITE}`,
                    }}
                  >
                    <></>
                  </GLBox>

                  <GLTypography
                    text="Ativos"
                    fontSize={3.5}
                    color="WHITE"
                    weight={400}
                  />
                </GLBox>

                <GLTypography
                  text="3.421"
                  fontSize={4}
                  color="WHITE"
                  weight={600}
                  style={{
                    paddingLeft: 15
                  }}
                />
              </GLBox>

              <GLBox
                direction="column"
                borderRadius="0 16px 16px 0"
                gap={5}
                style={{
                  padding: "20px 25px",
                }}
              >
                <GLBox align="center">
                  <GLBox
                    width={10}
                    height={10}
                    style={{
                      backgroundColor: GL_COLORS.DANGER,
                      borderRadius: "50%",
                      outline: `2px solid ${GL_COLORS.WHITE}`,
                    }}
                  >
                    <></>
                  </GLBox>

                  <GLTypography
                    text="Inativos"
                    fontSize={3.5}
                    color="WHITE"
                    weight={400}
                  />
                </GLBox>

                <GLTypography
                  text="3.421"
                  fontSize={4}
                  color="WHITE"
                  weight={600}
                  style={{
                    paddingLeft: 15
                  }}
                />
              </GLBox>
            </GLBox>

            <GLBox
              direction="column"
              style={{
                backgroundColor: GL_COLORS.WHITE,
                borderRadius: "16px",
                padding: "20px 25px",
                marginTop: 20,
                border: `1px solid ${GL_COLORS.BORDER_COLOR}`,
              }}
            >
              <GLBox align="center">
                <GLBox
                  width={12}
                  height={12}
                  style={{
                    backgroundColor: GL_COLORS.ACCENT_COLOR,
                    borderRadius: "50%",
                    outline: `2px solid ${GL_COLORS.WHITE}`,
                    flex: "0 0 12px",
                  }}
                >
                  <></>
                </GLBox>

                <GLTypography
                  text="Dispositivos de água"
                  fontSize={4}
                  color="FONT_COLOR"
                  weight={600}
                />
              </GLBox>

              <GLBox align="center">
                <GLBox
                  width={12}
                  height={12}
                  style={{
                    backgroundColor: GL_COLORS.DANGER,
                    borderRadius: "50%",
                    outline: `2px solid ${GL_COLORS.WHITE}`,
                    flex: "0 0 12px",
                  }}
                >
                  <></>
                </GLBox>

                <GLTypography
                  text="Dispositivos inativos"
                  fontSize={4}
                  color="FONT_COLOR"
                  weight={600}
                />
              </GLBox>
            </GLBox>
          </GLBox>
  {/*
          <GLBox
            direction="column"
            width="fit-content"
            // align="center"
          >
            {devicesFetched?.slice(0, 5).map((device, index) => (
              <GLBox
                key={device.device_id}
                width="fit-content"
                align="center"
                gap={10}
                style={{
                  padding: "10px 20px",
                  borderRadius: 10,
                  backgroundColor: GL_COLORS.DANGER,
                  boxShadow: `0px 4px 4px ${GL_COLORS.SHADOW_COLOR}`,
                  // marginTop: -20,
                }}
                initial={{
                  opacity: 0,
                  y: 20,
                }}

                animate={{
                  opacity: 1 / (index + 1),
                  // scale:
                    // index === 0 ? 1.1 : 1 / (index * 0.1 + 1),
                  y: 0,
                  transition: {
                    delay: index * 0.1,
                    duration: 0.5,
                  },
                }}

              >
                <GL_ALARM fill={GL_COLORS.WHITE}  />

                <GLBox direction="column" gap={5}>
                  <GLTypography
                    text="Magnetic fraud"
                    fontSize={4}
                    color="WHITE"
                    weight={600}
                  />
                  <GLTypography
                    text={String(device.device_id)}
                    fontSize={4}
                    color="WHITE"
                    weight={600}
                  />

                  <GLTypography
                    text={device.meta.display_address}
                    fontSize={3}
                    color="WHITE"
                    weight={400}
                  />
                </GLBox>
              </GLBox>
            ))}
          </GLBox> */}
        </HealthCheckMapFooter>

        <DeviceModal />
      </GoogleMap>
    </>

  ) : <></>;
}
