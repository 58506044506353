import {
  GLSelectMultipleOptions,
} from "@group-link-one/grouplink-components";
import { create } from "zustand";

export interface DeviceListStoreState {
  useCaseOptionsAvailables: GLSelectMultipleOptions[] | undefined
  useCaseOptionsSelecteds: GLSelectMultipleOptions[] | undefined
}

export interface DeviceListStoreActions {
  setUseCaseOptions: (useCaseOptions: GLSelectMultipleOptions[] | undefined) => void;
  setUseCaseOptionsSelecteds: (useCaseOptionsSelecteds: GLSelectMultipleOptions[] | undefined) => void;
  resetAll: () => void;
}

export interface DeviceListStore {
  state: DeviceListStoreState;
  actions: DeviceListStoreActions;
}

export const useDeviceListStore = create<DeviceListStore>((set) => ({
  state: {
    useCaseOptionsAvailables: undefined,
    useCaseOptionsSelecteds: undefined,
  },
  actions: {
    setUseCaseOptions: (useCaseOptionsAvailables) =>
      set((state) => ({
        state: {
          ...state.state,
          useCaseOptionsAvailables,
        }
      })),

    setUseCaseOptionsSelecteds: (useCaseOptionsSelecteds) =>
      set((state) => ({
        state: {
          ...state.state,
          useCaseOptionsSelecteds,
        }
      })),

    resetAll: () =>
      set(() => ({
        state: {
          useCaseOptionsAvailables: undefined,
          useCaseOptionsSelecteds: undefined,
        }
      })),
  },
}));
