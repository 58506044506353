import { Marker, MarkerClusterer } from "@react-google-maps/api"
import { useMemo } from "react"

import { GetDevicesActivatedLastReadingsResponse } from "../../../../Services/deviceListService/useDeviceListService.types"
import { useHealthCheckStore } from "../../store/health-check-store"

interface MarkerProps {
  device: GetDevicesActivatedLastReadingsResponse
  markerIcon?: string | google.maps.Icon | google.maps.Symbol | undefined
  clusterer: any | MarkerClusterer | undefined
}

export function MarkerMap({ device, clusterer }: MarkerProps) {

  const {
    state: healthCheckState,
    actions: healthCheckActions,
  } = useHealthCheckStore()

  const markers = {
    active: {
      url: "data:image/svg+xml;utf-8," + encodeURIComponent(`
        <svg width="36" height="36" xmlns="http://www.w3.org/2000/svg">
          <circle cx="18" cy="18" r="18" fill="#00FFAA" stroke="white" stroke-width="2"/>
        </svg>
      `),
      scaledSize: new window.google.maps.Size(22, 22),
    },
    activeSelected: {
      url: "data:image/svg+xml;utf-8," + encodeURIComponent(`
        <svg width="96" height="96" xmlns="http://www.w3.org/2000/svg">
            <!-- Raio maior com linha tracejada e fundo verde claro -->
            <circle cx="48" cy="48" r="44" fill="#ccffcc" stroke="#00FFAA" stroke-width="2" stroke-dasharray="4 2"/>
            <!-- Círculo interno centralizado -->
            <circle cx="48" cy="48" r="14" fill="#00FFAA" stroke="white" stroke-width="2"/>
        </svg>
      `),
      scaledSize: new window.google.maps.Size(96, 96), // Tamanho do ícone ajustado ao novo tamanho do SVG
    },
    withAlert: {
      url: "data:image/svg+xml;utf-8," + encodeURIComponent(`
        <svg width="36" height="36" xmlns="http://www.w3.org/2000/svg">
          <circle cx="18" cy="18" r="18" fill="#E12C47" stroke="white" stroke-width="2"/>
        </svg>
      `),
      scaledSize: new window.google.maps.Size(22, 22),
    },
    withAlertSelected: {
      url: "data:image/svg+xml;utf-8," + encodeURIComponent(`
        <svg width="96" height="96" xmlns="http://www.w3.org/2000/svg">
            <!-- Raio maior com linha tracejada e fundo verde claro -->
            <circle cx="48" cy="48" r="44" fill="#f88d9d" stroke="#E12C47" stroke-width="2" stroke-dasharray="4 2"/>
            <!-- Círculo interno centralizado -->
            <circle cx="48" cy="48" r="14" fill="#E12C47" stroke="white" stroke-width="2"/>
        </svg>
      `),
      scaledSize: new window.google.maps.Size(96, 96), // Tamanho do ícone ajustado ao novo tamanho do SVG
    },
  }

  function getMarkerIcon(deviceModalIsOpen: boolean, device_id?: number) {

    if (!device_id) return markers.active


    if (deviceModalIsOpen &&
      healthCheckState.deviceSelected?.device_id === device_id &&
      healthCheckState.deviceSelected?.channels[0].alerts.length === 0) {
      return markers.activeSelected
    }

    if (deviceModalIsOpen &&
      healthCheckState.deviceSelected?.channels[0].alerts &&
      healthCheckState.deviceSelected?.channels[0].alerts.length > 0 &&
      healthCheckState.deviceSelected?.device_id === device_id) {
      return markers.withAlertSelected
    }

    if (device.channels[0].alerts.length === 0) {
      return markers.active
    }

    if (device.channels[0].alerts && device.channels[0].alerts.length > 0) {
      return markers.withAlert
    }


  }

  const markerIcon = useMemo(() => {
    return getMarkerIcon(healthCheckState.deviceModalIsOpen, device?.device_id)
  }, [healthCheckState.deviceModalIsOpen, healthCheckState.deviceSelected, device?.device_id])

  return (
    <Marker
      key={device.device_id}
      position={{ lat: device.meta.latitude, lng: device.meta.longitude }}
      icon={markerIcon}
      clusterer={clusterer}
      animation={google.maps.Animation.DROP}
      onClick={(e: google.maps.MapMouseEvent) => {
        console.log("Marker clicked", e, device)
        healthCheckActions.setDeviceModalIsOpen(true)
        healthCheckActions.setDeviceSelected(device)
      }}
    />
  )
}
