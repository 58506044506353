import { BigNumberProps, Colors, GL_CHECK, GL_COLORS, GL_GLStation, GL_INTERNET, GL_LOGIN, useI18n } from "@group-link-one/grouplink-components";
import { t } from "i18next";
import { useMediaQuery } from "usehooks-ts";

import CheckImg from "../../../../images/DeviceList/icon-check.svg";
import InternetImg from "../../../../images/DeviceList/icon-internet.svg";
import LoginImg from "../../../../images/DeviceList/icon-log-in.svg";
import StationImg from "../../../../images/DeviceList/icon-station.svg";

export const useHealthCheckBigNumbers = () => {

  const isSM = useMediaQuery("(max-width: 660px)");

  const { selectedLanguege } = useI18n();

  const bigNumbers: BigNumberProps[] = [
    {
      iconLeft: (
        <GL_GLStation
          fillOne={GL_COLORS.ULTRADARK_GREY_SECONDARY}
          fill={GL_COLORS.ULTRADARK_GREY_SECONDARY}
          size={24}
        />
      ),
      iconRight: <img src={StationImg} alt="" />,
      iconRightStyle: {
        bottom: -20,
        right: isSM ? -40 : 20,
        transform: isSM ? "scale(0.85)" : "none",
      },
      title: t("deviceList.bigNumbers.one.title"),
      count: 0,
      type: isSM ? "sm" : "lg",
      backgroundColor: Colors.LIGHT_GREEN,
      textColor: "ULTRADARK_GREY_SECONDARY",
      tooltipText: t("deviceList.bigNumbers.one.tooltipDescription"),
      tooltipPosition: "top-center",
      tooltipTextStyle: {
        whiteSpace: "nowrap",
      }
    },
    {
      iconLeft: <GL_LOGIN fill={GL_COLORS.WHITE} size={24} />,
      iconRight: <img src={LoginImg} alt="" />,
      iconRightStyle: {
        bottom: -20,
        right: isSM ? -40 : 20,
        transform: isSM ? "scale(0.85)" : "none",
      },
      title: t("deviceList.bigNumbers.two.title"),
      count: 0,
      type: isSM ? "sm" : "lg",
      backgroundColor: Colors.NAVY,
      textColor: "WHITE",
      tooltipText: t("deviceList.bigNumbers.two.tooltipDescription"),
      helpIconColor: "WHITE",
      tooltipTextStyle: {
        whiteSpace: "nowrap",
      }
    },
    {
      iconLeft: <GL_CHECK fill={GL_COLORS.WHITE} size={24} />,
      iconRight: <img src={CheckImg} alt="" />,
      iconRightStyle: {
        bottom: 0,
        right: isSM ? -40 : 20,
        transform: isSM ? "scale(0.85)" : "none",
      },
      title: t("deviceList.bigNumbers.three.title"),
      count: 0,
      type: isSM ? "sm" : "lg",

      backgroundColor: GL_COLORS.ACCENT_COLOR,
      textColor: "WHITE",
      tooltipText: t("deviceList.bigNumbers.three.tooltipDescription"),
      helpIconColor: "WHITE",
      tooltipTextStyle: {
        whiteSpace: "nowrap",
      }
    },
    {
      iconLeft: <GL_INTERNET fill={GL_COLORS.WHITE} size={24} />,
      iconRight: <img src={InternetImg} alt="" />,
      iconRightStyle: {
        bottom: 0,
        right: isSM ? -40 : 20,
        transform: isSM ? "scale(0.85)" : "none",
      },
      title: t("deviceList.bigNumbers.four.title"),
      count: 0,
      type: isSM ? "sm" : "lg",
      backgroundColor: Colors.PURPLE,
      textColor: "WHITE",
      tooltipText: t("deviceList.bigNumbers.four.tooltipDescription"),
      helpIconColor: "WHITE",
      tooltipTextStyle: {
        whiteSpace: "nowrap",
      }
    },
  ];

  function formatBigNumber(value: number) {
    return value.toLocaleString(selectedLanguege, {
      maximumFractionDigits: 0,
    });
  }

  return {
    bigNumbers,
    formatBigNumber,
    isSM,
  };
};
