import { useAuth } from "@group-link-one/gl-auth";
import { IColumns } from "@group-link-one/grouplink-components";

import { useEnergyColumns } from "./Energy";
import { useWaterColumns } from "./Water";

export type UseCase = "util_water" | "util_kwh";

export interface AllColumns {
  columnName: string;
  hasActions?: boolean;
  useCases: UseCase[];
}

export const useAllColumns = () => {
  const { user } = useAuth();

  const userUseCase = user?.use_case as UseCase;

  if (!userUseCase) return [];

  const energyColumns = useEnergyColumns();
  const waterColumns = useWaterColumns();

  const columns: Record<UseCase, IColumns[]> = {
    util_water: waterColumns,
    util_kwh: energyColumns,
  };

  return columns[userUseCase];
};
