import { DateRange,GL_COLORS, GLRelativeFilterTimeProps, GLRelativeListOption } from "@group-link-one/grouplink-components";
import { useJsApiLoader } from "@react-google-maps/api";
import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect,useState } from "react";
import { useMediaQuery } from "usehooks-ts";
import { shallow } from "zustand/shallow"; // Pode usar 'shallow' como equalityFn para evitar re-renderizações

import { env } from "../../../env";
import { useDeviceListService } from "../../../Services/deviceListService/useDeviceListService";
// import { GetDevicesActivatedLastReadingsResponse } from "../../Services/deviceListService/useDeviceListService.types";
import { useThemeActiveStore } from "../../../store/theme";
import { mapDarkStyles, mapStyles } from "../../../utils/mapStyles";
import { useDeviceMapStore } from "../../HealthCheck/store/device-map-store";
import { useHealthCheckStore } from "../store/health-check-store";

export const useHealthCheckMap = () => {

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: env.REACT_APP_GOOGLE_MAPS_API_KEY
  })

  const { state: themeActiveState, actions: themeActiveActions } = useThemeActiveStore();
  const isMobile = useMediaQuery("(max-width: 1024px)");

  const [mapStyle, setMapStyle] = useState(
    themeActiveState.isDarkMode ? mapDarkStyles : mapStyles
  );

  const [map, setMap] = useState<google.maps.Map | null>(null)
  const [markerIcon, setMarkerIcon] = useState<google.maps.Icon | undefined>(undefined);

  const { getDevicesActivatedLastReadings } = useDeviceListService()

  const {
    state: healthCheckMapState,
    actions: healthCheckMapActions,
  } = useDeviceMapStore()

  const deviceSelected = useHealthCheckStore(
    (state) => state.state.deviceSelected,
    shallow
  );

  // const queryClient = useQueryClient()

  const center = {
    lat: -23.5932056,
    lng: -46.6780125
  };

  const onLoad = useCallback(function callback(mapInstance: google.maps.Map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    mapInstance.fitBounds(bounds);

    setMap(mapInstance)

    const svgIcon = {
      url: "data:image/svg+xml;utf-8," + encodeURIComponent(`
        <svg width="36" height="36" xmlns="http://www.w3.org/2000/svg">
          <circle cx="18" cy="18" r="18" fill="#00FFAA" stroke="white" stroke-width="2"/>
        </svg>
      `),
      scaledSize: new window.google.maps.Size(22, 22),
    };
    setMarkerIcon(svgIcon);

  }, [])

  const onUnmount = useCallback(function callback() {
    setMap(null)
  }, [])

  const { data: devicesFetched } = useQuery({
    queryKey: ["activateds-devices"],
    queryFn: async () => {
      const response = await getDevicesActivatedLastReadings({
        next_page_token: undefined,
        all_devices: true,
        ipp: 100,
      })

      if (response.has_more && response.next_page_token) {
        healthCheckMapActions.setNextToken(response.next_page_token)
      }

      return response.rows
    },
    refetchOnWindowFocus: false,
  })

  const relativeFilterTimeOptions: GLRelativeFilterTimeProps = {
    rootOptions: {
      width: !isMobile ? "fit-content" : "100%",
      style: {
        paddingRight: isMobile ? 5 : 0,
        background: isMobile ? "transparent" : "inherit",
        alignItems: isMobile ? "flex-end" : "initial",
      },
    },
    inputOptions: {
      width: "fit-content",
    },
    boxOptions: {
      position: "bottom-right",
      style: {
        boxShadow: `0px 4px 4px ${GL_COLORS.SHADOW_COLOR}`,
      },
    },
    // currentRelativeTime: detailsDeviceState.currentRelativeTime,
    currentRelativeTime: undefined,
    // currentDateRange: detailsDeviceState.currentDateRange,
    currentDateRange: undefined,

    setCurrentRelativeTime: (option?: GLRelativeListOption) => {
      // detailsDeviceActions.setCurrentRelativeTime(option);
      console.log(option);
    },

    setCurrentDateRange: (range?: DateRange) => {
      // detailsDeviceActions.setCurrentDateRange(range);
      console.log(range);
    },

    onRangeChange: async (range?: DateRange) => {
      console.log(range);
      // if (!detailsDeviceState.device) return;

      // const { device_id } = detailsDeviceState.device;
      // if (!range?.from || !range?.to || !device_id) return;

      // detailsDeviceActions.setRange(range);
      // detailsDeviceActions.setIsLoading(true);

      // await Promise.all([
      //   getDailyReadings({ device_id, range }),
      //   // getDowReadings({ device_id, range }),
      // ]);

      // detailsDeviceActions.setIsLoading(false);
    },
  };

  async function fetchMoreDevices(nextPageToken: string | undefined) {
    console.log("fetchMoreDevices", nextPageToken)
    // console.log("fetchMoreDevices", nextPageToken)
    // const response = await getDevicesActivatedLastReadings({
    //   next_page_token: nextPageToken,
    //   all_devices: true,
    //   ipp: 100,
    // })

    // if (response.has_more && response.next_page_token) {
    //   healthCheckMapActions.setNextToken(response.next_page_token)
    // } else {
    //   healthCheckMapActions.setNextToken(undefined)
    // }

    // const currentDevicesCached =
    //   queryClient.getQueryData<GetDevicesActivatedLastReadingsResponse[]>(["activateds-devices"])


    // if (currentDevicesCached && response.rows) {
    //   queryClient.setQueryData<GetDevicesActivatedLastReadingsResponse[]>(["activateds-devices"], {
    //     ...currentDevicesCached,
    //     ...response.rows
    //   })
    // }

  }

  useEffect(() => {
    if (map) {
      setTimeout(() => {
        map.setZoom(10)
      }, 400)
    }
  }, [map])

  useEffect(() => {
    if (healthCheckMapState.nextToken) {
      fetchMoreDevices(healthCheckMapState.nextToken)
    }
  }, [healthCheckMapState.nextToken])

  useEffect(() => {
    setMapStyle(themeActiveState.isDarkMode ? mapDarkStyles : mapStyles)
  }, [themeActiveState.isDarkMode])

  useEffect(() => {
    themeActiveActions.listenDarkMode();
  }, [])

  useEffect(() => {
    if (deviceSelected && map) {
      const latLng = new window.google.maps.LatLng(
        deviceSelected.meta.latitude,
        deviceSelected.meta.longitude
      );

      map.panTo(latLng);

      setTimeout(() => {
        map.setZoom(18);
      }, 600);
    }
  }, [deviceSelected, map]);

  return {
    center,
    devicesFetched,
    deviceSelected,
    markerIcon,
    mapStyle,
    relativeFilterTimeOptions,
    isLoaded,
    onLoad,
    onUnmount,
  };
};
