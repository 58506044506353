import {
  AlertType,
  formatValueSize,
  GL_ALARM,
  GL_ARROW_TRIANGLE_LEFT_FILL,
  GL_CHEVRON_DOWN,
  GL_COLORS,
  // GL_PENCIL,
  GL_PIN,
  GLBox,
  GLButton,
  GLDropdown,
  // GLTextField,
  GLTooltip,
  GLTypography,
} from "@group-link-one/grouplink-components";
import { format } from "date-fns";
import { motion } from "framer-motion";

import { getOptionsByApplication } from "../../utils/getOptionsByApplication";
import {
  AlertsContainer,
  DeviceNameContainer,
  InfosContainer,
  TitleContainer,
} from "./Style";
import { useDeviceDetailsTitleContent } from "./useDeviceDetailsTitleContent";

export function DeviceDetailsTitleContent() {
  const {
    device,
    langActive,
    userUseCase,
    currentChannelValue,
    currentChannelData,
    detailsDeviceState,
    t,
    alertsMap,
    editingDeviceNameValue,
    isEditingDeviceName,
    is1190px,
    isMobile,
    is768Px,
    editingRemoteIdValue,
    onDeviceNameClick,
    onAddressClick,
    onChannelChange,
  } = useDeviceDetailsTitleContent();

  const activateByText = `${currentChannelData?.activated_by} | ${currentChannelData?.activated_by_email}`;

  const boxInfoStyle: React.CSSProperties = {
    borderRadius: 15,
    backgroundColor: GL_COLORS.BACKGROUND_SECONDARY,
    padding: "15px 20px",
  };

  return (
    <GLBox
      width="100%"
      animation="fade-in-down"
      align="center"
      direction="column"
      transition={{
        delay: 0.3,
        duration: 1.4,
      }}
    >
      <TitleContainer>
        <GLBox
          layout
          direction="column"
          justify="flex-start"
          gap={5}
          width="auto"
        >
          <DeviceNameContainer
            layout
            className={isEditingDeviceName ? "editing" : ""}
            onClick={() => onDeviceNameClick("device_name")}
            tabIndex={0}
            role="button"
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                onDeviceNameClick("device_name");
              }
            }}
          >
            {/* <AnimatePresence > */}
            <GLTypography
              as="p"
              text={editingDeviceNameValue || ""}
              color="FONT_COLOR"
              weight={600}
              fontSize={is768Px ? 5.5 : 6.5}
              style={{
                whiteSpace: !is768Px ? "nowrap" : "normal",
                outline: "transparent",
              }}
            />
            {/* {isEditingDeviceName ? (
                <GLBox
                  layout
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -20 }}
                  style={{ maxWidth: 300 }}
                >
                  <GLTextField
                    ref={editInputRef}
                    border={true}
                    icon={<GL_PENCIL fill={GL_COLORS.ACCENT_COLOR} />}
                    defaultValue={editingDeviceNameValue}
                    style={{
                      fontWeight: "600",
                      fontSize: is768Px ? "22px" : "25px",
                    }}
                    max={50}
                    maxLength={50}
                    onBlur={onDeviceNameBlur}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") {
                        setTimeout(() => {
                          editInputRef.current?.blur();
                          setIsEditingDeviceName(false);
                        }, 100);
                      }
                    }}
                  />
                </GLBox>
              ) : (
                <motion.div
                  layout
                  initial={{ opacity: 0, x: 0 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: 0 }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 20,
                    width: "100%",
                  }}
                  className="device-name-text-box"
                >
                  <GLTypography
                    as="p"
                    text={editingDeviceNameValue || ""}
                    color="FONT_COLOR"
                    weight={600}
                    fontSize={is768Px ? 5.5 : 6.5}
                    style={{
                      whiteSpace: !is768Px ? "nowrap" : "normal",
                      outline: "transparent",
                    }}
                  />

                  <GL_PENCIL fill={GL_COLORS.ACCENT_COLOR} />
                </motion.div>
              )} */}
            {/* </AnimatePresence> */}
          </DeviceNameContainer>

          <GLTypography
            as="span"
            text={`ID ${device?.device_id || ""}`}
            color="DISABLED_FONT_COLOR"
            weight={600}
            fontSize={3}
            style={{ whiteSpace: "nowrap" }}
          />
        </GLBox>

        <GLBox layout>
          <GLTooltip
            text={
              device &&
              device?.meta?.display_address?.length &&
              device?.meta.display_address.length > 70
                ? device.meta.display_address
                : undefined
            }
          >
            <GLButton
              text={
                formatValueSize({
                  size: 70,
                  value: device?.meta.display_address || "",
                }) || "--"
              }
              variant="outline"
              align="left"
              border={false}
              weight={600}
              colorIconDynamically={true}
              icon={
                <GL_PIN
                  size={16}
                  fill={
                    getOptionsByApplication({
                      type: device?.application,
                    }).color
                  }
                />
              }
              color={
                getOptionsByApplication({
                  type: device?.application,
                }).color
              }
              style={{ width: "fit-content", textAlign: "left" }}
              onClick={onAddressClick}
            />
          </GLTooltip>
        </GLBox>
      </TitleContainer>

      {device && currentChannelData && currentChannelData.alerts.length > 0 && (
        <GLBox align="center" gap={20}>
          <GLTypography
            text="SYSTEM ALARMS"
            color="FONT_COLOR_DARK_GREY"
            as="span"
            fontSize={3.5}
            weight={600}
            style={{ whiteSpace: "nowrap" }}
          />

          <GL_ARROW_TRIANGLE_LEFT_FILL
            size={12}
            fill={GL_COLORS.DISABLED_FONT_COLOR}
          />

          <AlertsContainer>
            {currentChannelData.alerts.map((item, index) => (
              <motion.div
                key={index}
                initial={{
                  opacity: 0,
                  x: -20,
                }}
                animate={{
                  opacity: 1,
                  x: 0,
                }}
                transition={{
                  duration: 0.5,
                  delay: index * 0.15,
                }}
              >
                <GLBox
                  key={index}
                  align="center"
                  justify="center"
                  width="fit-content"
                  style={{
                    borderRadius: 20,
                    padding: "10px 15px",
                    border: `2px solid ${GL_COLORS.BORDER_COLOR}`,
                    backgroundColor: GL_COLORS.DANGER,
                    minWidth: 100,
                  }}
                >
                  <GL_ALARM size={16} fill={GL_COLORS.WHITE} />

                  <GLTypography
                    text={alertsMap[item as AlertType] || ""}
                    color="WHITE"
                    as="span"
                    fontSize={3.5}
                    weight={600}
                    style={{ whiteSpace: "nowrap" }}
                  />
                </GLBox>
              </motion.div>
            ))}
          </AlertsContainer>
        </GLBox>
      )}

      <GLBox
        align={isMobile ? "flex-start" : "flex-end"}
        justify={isMobile ? "flex-start" : "space-between"}
        direction={isMobile ? "column" : "row"}
        gap={20}
      >
        <InfosContainer direction="column">
          <GLBox>
            <GLBox
              width="fit-content"
              align="center"
              layout
              onClick={() => onDeviceNameClick("remote_id")}
              style={{
                ...boxInfoStyle,
                // cursor: "pointer",
                // padding: isEditingRemoteID ? "0px 0px 0px 0px" : "15px 20px",
              }}
            >
              <GLTypography
                text="REMOTE ID"
                color="FONT_COLOR_DARK_GREY"
                as="span"
                fontSize={is1190px ? 3 : 3.5}
                weight={600}
              />
              <GLTypography
                text={editingRemoteIdValue || "--"}
                color="TITLE_COLOR_SECONDARY"
                as="span"
                fontSize={is1190px ? 3 : 3.5}
                weight={600}
              />
              {/* {!isEditingRemoteID ? (
                <>
                  <GLTypography
                    text="REMOTE ID"
                    color="FONT_COLOR_DARK_GREY"
                    as="span"
                    fontSize={is1190px ? 3 : 3.5}
                    weight={600}
                  />
                  <GLTypography
                    text={editingRemoteIdValue || "--"}
                    color="TITLE_COLOR_SECONDARY"
                    as="span"
                    fontSize={is1190px ? 3 : 3.5}
                    weight={600}
                  />

                  <GL_PENCIL size={16} fill={GL_COLORS.ACCENT_COLOR} />
                </>
              ) : (
                <GLBox
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -20 }}
                  style={{ maxWidth: 300 }}
                >
                  <GLTextField
                    ref={remoteIDInputRef}
                    border={true}
                    icon={<GL_PENCIL fill={GL_COLORS.ACCENT_COLOR} />}
                    defaultValue={editingRemoteIdValue}
                    style={{
                      fontWeight: "600",
                      fontSize: is768Px ? "14px" : "16px",
                      backgroundColor: "transparent",
                    }}
                    max={50}
                    maxLength={50}
                    onBlur={onRemoteIDBlur}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") {
                        setTimeout(() => {
                          remoteIDInputRef.current?.blur();
                          setisEditingRemoteID(false);
                        }, 100);
                      }
                    }}
                  />
                </GLBox>
              )} */}
            </GLBox>

            {userUseCase === "util_water" && (
              <>
                <GLBox layout width="fit-content" style={{ ...boxInfoStyle }}>
                  <GLTypography
                    text="OFFSET"
                    color="FONT_COLOR_DARK_GREY"
                    as="span"
                    fontSize={is1190px ? 3 : 3.5}
                    weight={600}
                  />
                  <GLTypography
                    text={String(currentChannelData?.device_offset) || ""}
                    color="TITLE_COLOR_SECONDARY"
                    as="span"
                    fontSize={is1190px ? 3 : 3.5}
                    weight={600}
                  />
                </GLBox>

                <GLBox layout width="fit-content" style={{ ...boxInfoStyle }}>
                  <GLTypography
                    // text={`${currentChannelData?.unit || ""} / PULSO`}
                    text={`${
                      getOptionsByApplication({
                        type: detailsDeviceState.device?.application,
                      }).unit || ""
                    } / PULSO`}
                    color="FONT_COLOR_DARK_GREY"
                    as="span"
                    fontSize={is1190px ? 3 : 3.5}
                    weight={600}
                    style={{ textTransform: "uppercase" }}
                  />
                  <GLTypography
                    text={String(currentChannelData?.device_pulse_factor) || ""}
                    color="TITLE_COLOR_SECONDARY"
                    as="span"
                    fontSize={is1190px ? 3 : 3.5}
                    weight={600}
                  />
                </GLBox>
              </>
            )}

            <GLBox layout width="fit-content" style={{ ...boxInfoStyle }}>
              <GLTypography
                text={t("deviceList.detailsModal.activatedAt")}
                color="FONT_COLOR_DARK_GREY"
                as="span"
                fontSize={is1190px ? 3 : 3.5}
                weight={600}
                style={{ textTransform: "uppercase" }}
              />
              <GLTypography
                text={
                  device
                    ? format(
                        new Date(currentChannelData?.activated_at || ""),
                        "dd/MM/yyyy",
                        {
                          locale: langActive,
                        }
                      )
                    : ""
                }
                color="TITLE_COLOR_SECONDARY"
                as="span"
                fontSize={is1190px ? 3 : 3.5}
                weight={600}
              />
            </GLBox>
          </GLBox>

          <GLBox
            layout
            width="fit-content"
            style={{ ...boxInfoStyle, alignSelf: "flex-start" }}
          >
            <GLTypography
              text={t("deviceList.card.activatedBy")}
              color="FONT_COLOR_DARK_GREY"
              as="span"
              fontSize={is1190px ? 3 : 3.5}
              weight={600}
              style={{ textTransform: "uppercase" }}
            />
            <GLTypography
              text={activateByText || ""}
              color="TITLE_COLOR_SECONDARY"
              as="span"
              fontSize={is1190px ? 3 : 3.5}
              weight={600}
              style={{ wordBreak: "break-all" }}
            />
          </GLBox>
        </InfosContainer>

        {detailsDeviceState?.device?.channels &&
          detailsDeviceState.device.channels.length > 1 && (
            <GLDropdown.Root width="130px">
              <GLDropdown.Input
                width="100%"
                icon={
                  <GL_CHEVRON_DOWN
                    size={12}
                    fill={GL_COLORS.DISABLED_FONT_COLOR}
                  />
                }
                value={currentChannelValue}
                valueOptions={{
                  whiteSpace: "nowrap",
                  fontWeight: 600,
                }}
              />

              <GLDropdown.BoxOptions width="100%">
                {detailsDeviceState.device?.channels?.map((channel, index) => (
                  <GLDropdown.Option
                    key={index}
                    text={`Channel ${channel.channel + 1}`}
                    value={`Channel ${channel.channel + 1}`}
                    hasCheckInActive={false}
                    onClick={(value) => {
                      onChannelChange(value);
                    }}
                  />
                ))}
              </GLDropdown.BoxOptions>
            </GLDropdown.Root>
          )}
      </GLBox>
    </GLBox>
  );
}
