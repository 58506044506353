import { GoogleMap, Marker, MarkerClusterer, useJsApiLoader } from "@react-google-maps/api";

import { env } from "../../../../env";
import { useDeviceMap } from "./useDeviceMap";

const containerStyle = {
  width: "100%",
  height: "400px"
};

export function DevicesMap() {
  const {
    center,
    devicesFetched,
    markerIcon,
    mapStyle,
    onLoad,
    onUnmount
  } = useDeviceMap();

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: env.REACT_APP_GOOGLE_MAPS_API_KEY
  });

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{
        ...containerStyle,
        borderRadius: "10px",
      }}
      options={{
        styles: mapStyle,
        disableDefaultUI: true,
      }}
      center={center}
      zoom={10}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {devicesFetched && devicesFetched.length > 0 && (
        <MarkerClusterer
          options={{
            maxZoom: 14,

          }}
        >
          {(clusterer) =>
            <>
              {devicesFetched.map(device => (
                <Marker
                  key={device.device_id}
                  position={{ lat: device.meta.latitude, lng: device.meta.longitude }}
                  icon={markerIcon}
                  clusterer={clusterer}
                />
              ))}
            </>
          }
        </MarkerClusterer>
      )}
    </GoogleMap>
  ) : <></>;
}
