import { GLDeviceList } from "@group-link-one/grouplink-components";

// import { PageAnimated } from "../../utils/pageAnimated";
import { DeviceListContent } from "./Content/DeviceListContent";
import { ModalDeviceDetails } from "./ModalDeviceDetails/ModalDeviceDetails";
import { ModalEditDevice } from "./ModalEditDevice/ModalEditDevice";
import { useDeviceListBody } from "./useDeviceListBody";

export function DeviceListBody() {
  const {
    bigNumbers,
    deviceListState,
    onSelectUseCase,
    hasPrivilegeToShowBigNumbers,
  } = useDeviceListBody();

  return (
    <GLDeviceList
      useCaseOptions={deviceListState.useCaseOptionsAvailables}
      allDevicesOptions={{
        deviceListContent: <DeviceListContent />,
      }}
      bigNumbersOptions={{ bigNumbers }}
      modalDetails={<ModalDeviceDetails />}
      modalRegister={<ModalEditDevice />}
      hasPermissionToShowBigNumbers={hasPrivilegeToShowBigNumbers()}
      isOnTopHeaderContent={!hasPrivilegeToShowBigNumbers()}
      onSelectUseCase={(useCaseOptionsSelecteds) =>
        onSelectUseCase(useCaseOptionsSelecteds)
      }
    />
  );
}
