import { GLCard } from "@group-link-one/grouplink-components";

import {
  GetDevicesActivatedLastReadingsResponse,
  GetDevicesPendingActivationResponse,
} from "../../../Services/deviceListService/useDeviceListService.types";
import { useDeviceListCard } from "./useDeviceListCard";

interface DeviceListCardProps {
  item:
    | GetDevicesActivatedLastReadingsResponse
    | GetDevicesPendingActivationResponse;
}

export function DeviceListCard({ item }: DeviceListCardProps) {
  const {
    isMobile,
    onAddressClick,
    onDetailsClick,
    onEditClick,
    verifyIfUserHasPermissionToEdit,
  } = useDeviceListCard();

  function isPendingActivationCard(
    card: unknown
  ): card is GetDevicesPendingActivationResponse {
    const hasLastSeen =
      "last_seen" in (card as GetDevicesPendingActivationResponse);

    return !hasLastSeen;
  }

  if (isPendingActivationCard(item)) {
    return (
      <GLCard
        key={item.device_id}
        variant="ut-device-list-card"
        id={String(item.device_id)}
        cardType="pending"
        hasAlerts={true}
      />
    );
  }

  const hasAlerts = item.channels.some((channel) => channel.alerts?.length > 0);

  return (
    <GLCard
      key={item.device_id}
      variant="ut-device-list-card"
      cardType={item.meta.device_measurement_category}
      activated_by={{
        name: item.channels[0].activated_by || "--",
        email: item.channels[0].activated_by_email || "--",
      }}
      status="active"
      hasAlerts={hasAlerts}
      name={item.channels[0].name || "--"}
      address={item.meta.display_address || "--"}
      id={String(item.device_id)}
      lastEvent={item.last_seen}
      isMobile={isMobile}
      onAddressClick={() => {
        onAddressClick(item.meta.latitude, item.meta.longitude);
      }}
      onOpenDetails={() => onDetailsClick(item)}
      onOpenEdit={() => onEditClick(item)}
      canEdit={verifyIfUserHasPermissionToEdit()}
    />
  );
}
