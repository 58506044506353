import { GLBigNumberCard, GLBox } from "@group-link-one/grouplink-components";

import { DeviceOnlyMap } from "../DeviceOnlyMap/DeviceOnlyMap";
import { GraphConsumptionHistory } from "../Graphs/ConsumptionHistory/ConsumptionHistory";
import { GraphDailyConsumption } from "../Graphs/DailyConsumption/DailyConsumption";
import {
  BigNumbersContainer,
  ChartsContainer,
} from "./DeviceDetailsContentStyle";
import { useDeviceDetailsContent } from "./useDeviceDetailsContent";

export function DeviceDetailsContent() {
  const { bigNumbers, isLoading } = useDeviceDetailsContent();

  return (
    <GLBox width="100%" style={{ marginTop: 50 }} gap={50} direction="column">
      <BigNumbersContainer>
        {bigNumbers.map((bigNumber) => (
          <GLBigNumberCard
            key={bigNumber.title}
            bigNumber={bigNumber}
            animation="fade-in-down"
            transition={{
              delay: 0.4,
              duration: 1.4,
            }}
            isLoading={isLoading}
          />
        ))}
      </BigNumbersContainer>

      <ChartsContainer
        direction="row"
        gap={20}
        animation="fade-in-down"
        transition={{
          delay: 0.5,
          duration: 1.4,
        }}
      >
        <GraphConsumptionHistory />
        <GraphDailyConsumption />
      </ChartsContainer>

      <DeviceOnlyMap />

      {/* <GLBox direction="column">
        <GLBox align="center">
          <GLBox direction="column">
            <GLBox align="center">
              <GLTypography
                text="HEATMAP"
                fontSize={4}
                color="DISABLED_FONT_COLOR"
                weight={600}
                as="h3"
              />

              <GL_HELP size={12} fill={GL_COLORS.NAVY} />
            </GLBox>

            <GLTypography
              text="Weekly peak analysis"
              fontSize={4}
              color="TITLE_COLOR_SECONDARY"
              weight={600}
              as="p"
            />
          </GLBox>

          <GLDateRange
            from={undefined}
            to={undefined}
            boxOptions={{
              width: "fit-content",
              position: "bottom-right",
              style: {
                zIndex: 100,
              },
            }}
            inputOptions={{
              width: "280px",
            }}
            labelOptions={{
              text: "Date range",
              isRequired: true,
              informationText: "(7 days max)",
            }}
            rootOptions={{}}
          />
        </GLBox>

        <GLMatrixChart columns={columns} rows={rows} values={values} />
      </GLBox> */}
    </GLBox>
  );
}
