import {
  GL_COLORS,
  GLPieChartDataProps,
} from "@group-link-one/grouplink-components";

export const useTransmissionStatus = () => {
  const data: GLPieChartDataProps[] = [
    {
      label: "Azul_c",
      value: 2500,
      color: GL_COLORS.ACCENT_COLOR,
      description: "users",
      hasTooltip: true,
    },
    {
      label: "Tokio marine",
      value: 2500,
      color: GL_COLORS.SUCCESS,
      description: "users",
      hasTooltip: true,
    },
    {
      value: 2500,
      label: "Other",
      color: GL_COLORS.DISABLED_FONT_COLOR,
      description: "users",
      hasTooltip: true,
    },
  ];

  return { data };
};
