import { useJsApiLoader } from "@react-google-maps/api";
import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect,useState } from "react";

import { env } from "../../../../env";
import { useDeviceListService } from "../../../../Services/deviceListService/useDeviceListService";
import { useThemeActiveStore } from "../../../../store/theme";
import { mapDarkStyles, mapStyles } from "../../../../utils/mapStyles";
// import { GetDevicesActivatedLastReadingsResponse } from "../../Services/deviceListService/useDeviceListService.types";
import { useDeviceMapStore } from "../../store/device-map-store";

export const useDeviceMap = () => {

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: env.REACT_APP_GOOGLE_MAPS_API_KEY
  })

  const { state: themeActiveState, actions: themeActiveActions } = useThemeActiveStore();

  const [mapStyle, setMapStyle] = useState(
    themeActiveState.isDarkMode ? mapDarkStyles : mapStyles
  );

  const [map, setMap] = useState<google.maps.Map | null>(null)
  const [markerIcon, setMarkerIcon] = useState<google.maps.Icon | undefined>(undefined);

  const { getDevicesActivatedLastReadings } = useDeviceListService()
  const {
    state: deviceMapState,
    actions: deviceMapActions,
  } = useDeviceMapStore()

  // const queryClient = useQueryClient()

  const center = {
    lat: -23.5932056,
    lng: -46.6780125
  };

  const onLoad = useCallback(function callback(mapInstance: google.maps.Map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    mapInstance.fitBounds(bounds);

    setMap(mapInstance)

    const svgIcon = {
      url: "data:image/svg+xml;utf-8," + encodeURIComponent(`
        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
          <circle cx="12" cy="12" r="10" fill="#00FFAA" stroke="white" stroke-width="2"/>
        </svg>
      `),
      scaledSize: new window.google.maps.Size(16, 16),
    };
    setMarkerIcon(svgIcon);

  }, [])

  const onUnmount = useCallback(function callback() {
    setMap(null)
  }, [])

  const { data: devicesFetched } = useQuery({
    queryKey: ["activateds-devices"],
    queryFn: async () => {
      const response = await getDevicesActivatedLastReadings({
        next_page_token: undefined,
        all_devices: true,
        ipp: 100,
      })

      if (response.has_more && response.next_page_token) {
        deviceMapActions.setNextToken(response.next_page_token)
      }

      return response.rows
    },
    refetchOnWindowFocus: false,
  })

  async function fetchMoreDevices(nextPageToken: string | undefined) {
    console.log("fetchMoreDevices", nextPageToken)
    // const response = await getDevicesActivatedLastReadings({
    //   next_page_token: nextPageToken,
    //   all_devices: true,
    //   ipp: 100,
    // })

    // if (response.has_more && response.next_page_token) {
    //   deviceMapActions.setNextToken(response.next_page_token)
    // } else {
    //   deviceMapActions.setNextToken(undefined)
    // }

    // const currentDevicesCached =
    //   queryClient.getQueryData<GetDevicesActivatedLastReadingsResponse[]>(["activateds-devices"])


    // if (currentDevicesCached && response.rows) {
    //   queryClient.setQueryData<GetDevicesActivatedLastReadingsResponse[]>(["activateds-devices"], {
    //     ...currentDevicesCached,
    //     ...response.rows
    //   })
    // }

  }

  useEffect(() => {
    if (map) {
      setTimeout(() => {
        map.setZoom(10)
      }, 400)
    }
  }, [map])

  useEffect(() => {
    if (deviceMapState.nextToken) {
      fetchMoreDevices(deviceMapState.nextToken)
    }
  }, [deviceMapState.nextToken])

  useEffect(() => {
    setMapStyle(themeActiveState.isDarkMode ? mapDarkStyles : mapStyles)
  }, [themeActiveState.isDarkMode])

  useEffect(() => {
    themeActiveActions.listenDarkMode();
  }, [])

  return {
    center,
    devicesFetched,
    markerIcon,
    mapStyle,
    isLoaded,
    onLoad,
    onUnmount,
  };
};
