import {
  GLHeaderNavListItemProps,
  useI18n,
} from "@group-link-one/grouplink-components";
import { useMemo } from "react";

import { useFlags } from "../../Context/FlagsProvider";

export enum SettingsRoutes {
  DEFAULT = "/settings",
  OVERVIEW = "/settings/overview",
  MY_ACCOUNT = "/settings/my-account",
  LOGIN_SECURITY = "/settings/login-security",
  USERS = "/settings/users",
  GROUPS_USERS = "/settings/groups-users",
  ORGANIZATIONS = "/settings/organizations",
  APPS = "/settings/apps",
  AUDIT = "/settings/audit",
}

export function useSettingsLayout() {
  const { t } = useI18n();
  const { flags, hasPrivileges, user, hasBetaPrivileges, flagsAlreadyLoaded } =
    useFlags();

  const headerNavList: GLHeaderNavListItemProps[] = useMemo(() => {
    const items = [
      {
        id: 1,
        title: t("pages.deviceOverview.overview"),
        path: SettingsRoutes.OVERVIEW,
        isToAppear: true,
      },
      {
        id: 2,
        title: t("pages.settings.myAccount"),
        path: SettingsRoutes.MY_ACCOUNT,
        isToAppear: true,
      },
      {
        id: 3,
        title: t("pages.settings.loginSecurity"),
        path: SettingsRoutes.LOGIN_SECURITY,
        isToAppear: true,
      },
      {
        id: 4,
        title: t("pages.settings.apps"),
        path: SettingsRoutes.APPS,
        isToAppear: hasPrivileges(["apps_get"]),
      },
      {
        id: 5,
        title: t("users.subtitle"),
        path: SettingsRoutes.USERS,
        isToAppear: hasPrivileges(["organization_user_get"]),
      },
      {
        id: 7,
        title: t("audit.title"),
        path: SettingsRoutes.AUDIT,
        isToAppear: hasPrivileges(["devices_activated_last_reading"]),
      },
      {
        id: 8,
        title: t("groupsUsers.subtitle"),
        path: SettingsRoutes.GROUPS_USERS,
        isToAppear: hasPrivileges(["show_settings_user_group_page"]),
      },
    ];

    return items.filter((item) => item.isToAppear);
  }, [flags, user, flagsAlreadyLoaded, hasPrivileges, hasBetaPrivileges]);

  const headerNavItemActive = headerNavList.find(
    (navItem) => navItem.path === window.location.pathname
  );

  return {
    headerNavList,
    headerNavItemActive,
  };
}
