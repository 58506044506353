import { GLBigNumberCard } from "@group-link-one/grouplink-components";
import React from "react";

import { DeviceListBigNumbersContainer } from "./HealthCheckBigNumbersStyle";
import { useHealthCheckBigNumbers } from "./useHealthCheckBigNumbers";

export function HealthCheckBigNumbers() {
  const { bigNumbers, isSM } = useHealthCheckBigNumbers();

  return (
    <DeviceListBigNumbersContainer direction="row" gap={isSM ? 15 : 20}>
      {bigNumbers &&
        bigNumbers.map((bigNumber, index) => (
          <GLBigNumberCard bigNumber={bigNumber} key={index} />
        ))}
    </DeviceListBigNumbersContainer>
  );
}
