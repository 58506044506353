import { GLSettingsGroupsUsers } from "@group-link-one/grouplink-components";

import { GroupUsersTransferList } from "./TransferList/GroupUsersTransferList";
import { useOrganizationGroups } from "./useOrganizationGroups";

export const GroupsUsersPage = () => {
  const { groups, listGroups, createGroup, updateGroup, deleteGroup } =
    useOrganizationGroups();

  return (
    <GLSettingsGroupsUsers
      hasPrivilegeToCreate={true}
      groups={groups}
      transferListContent={<GroupUsersTransferList />}
      onListGroups={listGroups}
      onCreateGroup={createGroup}
      onUpdateGroup={updateGroup}
      onDeleteGroupById={deleteGroup}
    />
  );
};
