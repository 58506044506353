import { GLBox, GLBoxChart } from "@group-link-one/grouplink-components";

import { HealthCheckBigNumbers } from "./BigNumbers/HealthCheckBigNumbers";
import { DevicesMap } from "./Map/DeviceMap";
import { TransmissionStatusChart } from "./TransmissionStatus/TransmissionStatus";
// import { useHealthCheckContent } from "./useHealthCheckContent";

export function HealthCheckContent() {
  // const {
  //   t
  // } = useHealthCheckContent();

  return (
    <>
      <HealthCheckBigNumbers />

      <GLBox
        style={{ marginTop: 20 }}
        height="100%"

      >
        <GLBoxChart
          title="Status de transmissão"
          textSwitcherId="deviceList.chart.textSwitcher"
          marginBottomTitle={0}
          chart={
            <GLBox
              style={{
                display: "grid",
                gridTemplateColumns: "0.4fr 0.6fr",
              }}
            >
              <TransmissionStatusChart />
              <DevicesMap />
            </GLBox>
          }

          />
      </GLBox>

    </>
  );
}
