import { GL_COLORS, GL_DELETE, GLBox, GLButton, GLButtonIcon, GLTypography } from "@group-link-one/grouplink-components";

// import { env } from "../../../env";
import { getOptionsByApplication } from "../../DeviceList/utils/getOptionsByApplication";
import { HealthCheckModal } from "./DeviceModalStyle";
import { useDeviceModal } from "./useDeviceModal";

export function DeviceModal() {
  const {
    healthCheckState,
    healthCheckActions,
    onClickSeeDetails
  } = useDeviceModal();

  // const lat = healthCheckState.deviceSelected?.meta.latitude
  // const lng = healthCheckState.deviceSelected?.meta.longitude
  // const apiKey = env.REACT_APP_GOOGLE_MAPS_API_KEY

  // const mapImageUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=15&size=600x400&maptype=roadmap
  // &markers=color:red%7Clabel:L%7C${lat},${lng}&key=${apiKey}`;

  return (
    <HealthCheckModal
      initial={{
        opacity: 0,
        x: 50,

      }}
      animate={{
        opacity: healthCheckState.deviceModalIsOpen ? 1 : 0,
        x: healthCheckState.deviceModalIsOpen ? 0 : 50,
        pointerEvents: healthCheckState.deviceModalIsOpen ? "all" : "none",
        animation: ["easeInOut"],
        transition: {
          duration: 0.2,
        },
      }}
    >
      <GLBox
        height="100%"
        justify="center"
        align="center"
        direction="column"
        style={{ position: "relative" }}
      >
        <GLButtonIcon
          variant="outline"
          icon={<GL_DELETE />}
          color={GL_COLORS.FONT_COLOR}
          style={{
            position: "absolute",
            top: -10,
            right: -10,
          }}
          onClick={() => {
            healthCheckActions.setDeviceModalIsOpen(false)
          }}
        />

        <GLBox
          width={110}
          height={110}
          justify="center"
          align="center"
          borderRadius="50%"
          style={{
            backgroundColor: GL_COLORS.ACCENT_COLOR,
            boxShadow: `0px 4px 4px ${GL_COLORS.SHADOW_COLOR}`,
            marginBottom: 10,
          }}
        >
          {getOptionsByApplication({ type: "GLUtilitiesWater" }).icon(64, GL_COLORS.WHITE)}
        </GLBox>

        <GLTypography
          text="Device name"
          weight={600}
          fontSize={6}
          color="FONT_COLOR"
        />

        <GLBox align="center" justify="center" gap={5}>
          <GLTypography
            text="Device ID:"
            weight={400}
            fontSize={4}
            color="FONT_COLOR"
          />
          <GLTypography
            text="123456213"
            weight={600}
            fontSize={4}
            color="FONT_COLOR"
          />
        </GLBox>

        <GLBox
          height={1}
          width="100%"
          style={{
            backgroundColor: GL_COLORS.BORDER_COLOR,
            margin: "10px 0",
          }}
        >
          <></>
        </GLBox>

        <GLBox>
          <GLBox direction="column" gap={5}>
            <GLTypography
              text="Ultima leitura:"
              weight={600}
              fontSize={4}
              color="FONT_COLOR"
            />
            <GLTypography
              text={"6504.519 " + getOptionsByApplication({ type: "GLUtilitiesWater" }).unit}
              weight={400}
              fontSize={4}
              color="FONT_COLOR"
            />
          </GLBox>

          <GLBox direction="column" gap={5}>
            <GLTypography
              text="Lido por último em:"
              weight={600}
              fontSize={4}
              color="FONT_COLOR"
            />
            <GLTypography
              text="12/12/2024 12:00"
              weight={400}
              fontSize={4}
              color="FONT_COLOR"
            />
          </GLBox>

          {/* <img src={mapImageUrl} alt="" /> */}
        </GLBox>

        <GLBox
          align="center"
          justify="center"
          height="fit-content"
          gap={5}
          style={{
            marginTop: "auto"
          }}
        >
          <GLButton
            align="center"
            variant="fill"
            text="Ver detalhes"
            weight={600}
            onClick={() => onClickSeeDetails()}
          />
        </GLBox>
      </GLBox>
    </HealthCheckModal>
  )
}
