import { useAuth } from "@group-link-one/gl-auth";
import { GLLoadingPage } from "@group-link-one/grouplink-components";
import { Navigate } from "react-router-dom";

import { useFlags } from "../Context/FlagsProvider";

interface VerifyAuthenticatedRouteProps {
  needAuth?: boolean;
  element: React.ReactElement;
  userHasPrivileges?: boolean;
  onlyPrivileges?: boolean;
  onlyBetaPrivileges?: boolean;
  onlyBetaClientPrivileges?: boolean;
}

export const VerifyRoute = ({
  needAuth,
  element,
  userHasPrivileges = undefined,
  onlyPrivileges = false,
  onlyBetaPrivileges = false,
  onlyBetaClientPrivileges = false,
}: VerifyAuthenticatedRouteProps): JSX.Element => {
  const { accessToken } = useAuth();
  const { isPending } = useAuth();
  const { user, hasBetaPrivileges, flagsAlreadyLoaded } = useFlags();

  const isAccessTokenMissing = !accessToken;
  const isLoading = isPending || !user || !flagsAlreadyLoaded;
  const isOnlyBetaPrivileges = onlyBetaPrivileges;

  if (needAuth && isAccessTokenMissing) return <Navigate to="/" replace />;
  if (needAuth && isLoading) return <GLLoadingPage />;

  if (needAuth && isOnlyBetaPrivileges && !onlyBetaClientPrivileges && !hasBetaPrivileges("beta")) {
    return <Navigate to="/" replace />;
  }

  if (needAuth && onlyBetaClientPrivileges && !isOnlyBetaPrivileges && !hasBetaPrivileges("beta_client")) {
    return <Navigate to="/" replace />;
  }

  if (needAuth &&
    isOnlyBetaPrivileges &&
    onlyBetaClientPrivileges &&
    (!hasBetaPrivileges("beta") && !hasBetaPrivileges("beta_client"))
  ) {
    return <Navigate to="/" replace />;
  }

  if (needAuth && onlyPrivileges && !userHasPrivileges) {
    return <Navigate to="/" replace />;
  }

  if (!needAuth && !isAccessTokenMissing) return <Navigate to="/" replace />;

  return element;
};
