import { create } from "zustand";

export interface DeviceMapStoreState {
  nextToken: string | undefined;
}

export interface DeviceMapStoreActions {
  setNextToken: (nextToken: string | undefined) => void;
}

export interface DeviceMapStore {
  state: DeviceMapStoreState;
  actions: DeviceMapStoreActions;
}

export const useDeviceMapStore = create<DeviceMapStore>((set) => ({
  state: {
    nextToken: undefined,
  },
  actions: {
    setNextToken: (nextToken) => {
      set((state) => {
        return {
          state: {
            ...state.state,
            nextToken,
          }
        };
      });
    }
  },
}));
